<template>
<div>
          <top-statistics />
  <b-row>
      <!-- <b-col
        lg="12"
        cols="1"
      >
        <top-statistics />
      </b-col> -->
      <b-col
        lg="6"
        cols="12"
      >
        <countries />
      </b-col>

      <b-col
        lg="6"
        cols="12"
      >
        <b-row>
          <b-col
        lg="12"
        cols="12"
      >
          <products />
      </b-col>
      <b-col
        lg="12"
        cols="12"
      >
        <accessories />
      </b-col>

        </b-row>      
      </b-col>
    </b-row>
</div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import TopStatistics from '../components/Dashboard/Statistics/Top.vue'
import Countries from '../components/Dashboard/Statistics/Countries.vue'
import Products from '../components/Dashboard/Statistics/Products.vue'
import Accessories from '../components/Dashboard/Statistics/Accessories.vue'


export default {
  components: {
    BRow,
    BCol,

    TopStatistics,
    Countries,
    Products,
    Accessories

  },
  data() {
    return {
      data: {},
    }
  },
  created() {
   // let's do something here
  },
}
</script>
