<template>
  <b-card
    title="Row select support"
    no-body
  >

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="activeCountries"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(flag)="data">
        <b-avatar :src="data.value" />
      </template>
    
    </b-table>


  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
    mapGetters
} from "vuex";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
   computed: {
        ...mapGetters(["activeCountries"]),
    },

    mounted() {
        this.$store.dispatch("GET_COUNTRIES");
    },
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      fields: [
          { key: "flag", label: "", tdStyle: { width: '10px'}},
          'name', 
          { key: "sales", label: "Sales", thClass: 'width-100'},
          { key: "products", label: "products", thClass: 'width-100'},
          { key: "accessories", label: "accs", thClass: 'width-100'},
          { key: "basket", label: "basket", thClass: 'width-100'},
          { key: "deliveries", label: "deliveries", thClass: 'width-100'},
          { key: "amount", label: "amount", thClass: 'width-100'},
          ],
      /* eslint-disable global-require */
      selectMode: 'multi',
      selected: [],
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.width-100{
    width: 10px;
}
</style>
