<template>
  <b-card
    title="Row select support"
    no-body
  >

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(img)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #row(flag)="data">
      </template>
    
    </b-table>


  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
    mapGetters
} from "vuex";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
   computed: {
        ...mapGetters(["activeCountries"]),
    },

    mounted() {
        this.$store.dispatch("GET_COUNTRIES");
    },
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      fields: [
          'name', 
          { key: "sales", label: "Sales", tdStyle: { width: '10px'}},
          { key: "products", label: "products", class: 'width-100'}, 
          { key: "aq", label: "aq", tdClass: 'width-100'},
        //   { key: "amount", label: "amount", tdClass: 'width-100'},
          ],
      /* eslint-disable global-require */
      items: [
        {
          name: '5x AirShield PM 2.5 Filters',
          sales: '12',
          products: '1',
          aq: '1',
          amomunt: '2'
        },
        {
          name: 'Pole - Solar Street Lamp	',
          sales: '12',
          products: '1',
          aq: '1',
          amomunt: '2'
        },
        {
          name: 'Pole Bolts - Solar Street Lamp	',
          sales: '12',
          products: '1',
          aq: '1',
          amomunt: '2'
        },
        {
          name: 'Bicycle Mount	',
          sales: '12',
          products: '1',
          aq: '1',
          amomunt: '2'
        },
        {
          name: 'CarCharger	',
          sales: '12',
          products: '1',
          aq: '1',
          amomunt: '2'
        }
      ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.width-100{
    width: 10px;
}
</style>
